<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols='12'>
          <v-card-title >
            5. Порядок проведения эвакуации
          </v-card-title>
        </v-col>
        <v-col
          cols="8"
          class="pb-0"
        >
            <v-alert
              border="left"
              colored-border
              color="red"
              class="title font-weight-light pl-10"
              elevation="2"
              height="130"
            >
            <strong>Эвакуация </strong> - вынужденное организованное самостоятельное движение людей наружу или в безопасную зону из помещений, где возможно воздействие опасных факторов пожара.
          </v-alert>
        </v-col>
        <v-col cols="4">
          <v-dialog
            v-model="dialog1"
            width="50%"
            class="pa-16"
          >
            <template v-slot:activator="{ on, attrs }">
                <v-card
                  class="pa-2 d-flex justify-center align-center"
                  full-width
                  v-bind="attrs"
                  v-on="on"
                  tag="a"
                  height="130"
                  hover

                  >
                <v-img
                  lazy-src="img/material/fire.svg"
                  max-width="80"
                  src="img/material/fire.svg"
                  class="ma-1"
                ></v-img>
                <v-spacer></v-spacer>
                <v-card-text class="title font-weight-light">
                  Эвакуация осуществляется
                </v-card-text>
              </v-card>
            </template>
            <v-card class="noselect">
              <v-row class="align-center">
                <v-col cols="8">
                <div class="pa-5">
                  <v-card-title class="headline">
                    Эвакуация осуществляется эвакуационными путями через эвакуационные выходы, указанные в плане эвакуации:
                  </v-card-title>
                  <v-card-text class="title font-weight-light mb-0 pb-0">
                    <v-icon color="red" class="">mdi-check-all</v-icon>
                    <span class="ml-5 red--text title">Путь эвакуации</span> – последовательность коммуникационных участков, ведущих от мест пребывания людей в безопасную зону;
                  </v-card-text>
                  <v-card-text class="title font-weight-light mb-0 pb-0">
                    <v-icon color="red" class="">mdi-check-all</v-icon>
                    <span class="ml-5 red--text title">Эвакуационный выход </span> – пвыход на путь эвакуации, ведущий в безопасную зону и отвечающий требованиям безопасности;
                  </v-card-text>
                  <v-card-text class="title font-weight-bold mb-0 pb-0">
                    Основные требования ПБ к путям эвакуации, выходам определены в СП 1.13130.2020.
                  </v-card-text>
                </div>
                </v-col>
                <v-col cols="4">
                  <v-img
                    lazy-src="img/material/plan.jpg"
                    width="60%"
                    height="80%"
                    src="img/material/plan.jpg"
                    class="ma-10 pa-5"
                    contain
                  ></v-img>
                </v-col>
              </v-row>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="red"
                  text
                  @click="dialog1 = false"
                >
                  Закрыть
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-col>
        <v-col cols="12">
          <v-card-title >
            Эвакуация делится на этапы:
          </v-card-title>
            <v-timeline>
              <v-timeline-item
                v-for="n in steps"
                :key="n"
                color="red"
                large
              >
                <template v-slot:opposite>

                  <span><v-icon color="red" size="100">{{ n.step }}</v-icon></span>
                </template>
                <v-card class="elevation-2">
                  <v-card-title class="headline">
                    {{ n.title }}
                  </v-card-title>
                  <v-card-text class="title font-weight-light" v-html="n.desc">
                  </v-card-text>
                </v-card>
              </v-timeline-item>
            </v-timeline>
        </v-col>
      </v-row>
      <Hint></Hint>
    </v-container>
  </div>
</template>
<script>
import Hint from '@/components/Hint.vue'
export default {
  data: () => ({
    dialog1: null,
    steps: [
      { step: 'mdi-numeric-1', title: '1 ЭТАП', desc: 'Движение от наиболее удаленной точки помещения до эвакуационного выхода из него.<br>У этапа короткий срок завершения, т.к. необходимо удалить людей до развития пожара.' },
      { step: 'mdi-numeric-2', title: '2 ЭТАП', desc: 'Движение (Движение происходит по коридорам, проходам и фойе к лестницам и по лестницам, вестибюлям до выходов наружу) от эвакуационных выходов до выходов наружу.<br>Этап менее опасен и может быть более продолжительным.' },
      { step: 'mdi-numeric-3', title: '3 ЭТАП', desc: 'Движение от выхода из здания и рассеивание их в городском потоке или на территории объекта.<br>Время проведения не нормируется.' }
    ]
  }),
  components: {
    Hint
  }
}
</script>
